import React from 'react'
import star from '../Assets/img/str.svg'
import google from '../Assets/img/google-reviews.jpg'
import yelp from '../Assets/img/yelp-reviews.jpg'
import ivouch from '../Assets/img/ivouch-reviews.jpg'
import { Container, Row, Tab, Tabs } from 'react-bootstrap'
import Slider from 'react-slick'
import { ArrowBack, ArrowBackIos, ArrowForward, ArrowForwardIos } from '@mui/icons-material'
import Larrow from '../Assets/img/left.png'
import Rarrow from '../Assets/img/right.png'
const GoogleReview = () => {

    var settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        accessibility: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        nextArrow: <img src={Rarrow} alt='' />,
        prevArrow: <img src={Larrow} alt='' />,
    }
    return (
        <div class="GoogleReview mb-0">
            <Container className='London_theme_conteiner'>
                <div class="title-box2 text-center">
                    <h2 class="title">What People Say</h2>
                </div>
                <div class="client-tabs">
                    <Tabs
                        defaultActiveKey="home"
                        className="justify-content-center mb-3"
                        variant='none'
                        transition={false}
                    >
                        <Tab eventKey="home" title={<img src={google} alt="goog-reviews" width={130} height={76} />}>
                            <Slider {...settings2}>
                                <div class="item-testimo2 text-center">
                                    <p class="desc ">“Wow, Lou did an AMAZING job with my ring! My 6 month old Tiffany engagement ring diamond FELL out - luckily I was able to find it. <a href="https://www.google.com/search?q=duvall+goldsmiths&rlz=1C1CHZN_enIN1092IN1092&oq=duvall+goldsmiths&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyDQgBEC4YrwEYxwEYgAQyBggCEEUYQDIHCAMQABiABDIHCAQQABiABDIGCAUQRRg80gEIMTY5M2owajeoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x54900bfb67ea4211:0x9ff9f645d5806575,1" target="_blank" class="review_read_more" rel="noreferrer">Read more...</a>
                                    </p>
                                    <ul class="reviews_icon">
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                    </ul>
                                    <h3 class="name">Victoria Baber</h3>
                                </div>
                                <div class="item-testimo2 text-center">
                                    <p class="desc ">Lou did an amazing g job making a custom setting for my ellensburg blue!  He made sure the design would work well for my job since I am a hairstylist and hair tends to get stuck in some of my rings. <a href="https://www.google.com/search?q=duvall+goldsmiths&rlz=1C1CHZN_enIN1092IN1092&oq=duvall+goldsmiths&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyDQgBEC4YrwEYxwEYgAQyBggCEEUYQDIHCAMQABiABDIHCAQQABiABDIGCAUQRRg80gEIMTY5M2owajeoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x54900bfb67ea4211:0x9ff9f645d5806575,1" target="_blank" class="review_read_more" rel="noreferrer">Read more...</a></p>
                                    <ul class="reviews_icon">
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                    </ul>
                                    <h3 class="name">Kari Branthwaite</h3>
                                </div>
                                <div class="item-testimo2 text-center">
                                    <p class="desc ">Fabulous. I stupidly snapped the crown and stem on my Tag Heuer Aquaracer. My watch was fixed up, with factory parts, on schedule. My battery also needed to be replaced, and was included in the original quote and not tacked on as extra. <a href="https://www.google.com/search?q=duvall+goldsmiths&rlz=1C1CHZN_enIN1092IN1092&oq=duvall+goldsmiths&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyDQgBEC4YrwEYxwEYgAQyBggCEEUYQDIHCAMQABiABDIHCAQQABiABDIGCAUQRRg80gEIMTY5M2owajeoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x54900bfb67ea4211:0x9ff9f645d5806575,1" target="_blank" class="review_read_more" rel="noreferrer">Read more...</a></p>
                                    <ul class="reviews_icon">
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                    </ul>
                                    <h3 class="name">Maxine Lo</h3>
                                </div>
                                <div class="item-testimo2 text-center">
                                    <p class="desc ">I always wonder how businesses keep a solid 5-star standing after more than a few months of business. Without question, completely understand how Duvall Goldsmiths are able to keep it.<a href="https://www.google.com/search?q=duvall+goldsmiths&rlz=1C1CHZN_enIN1092IN1092&oq=duvall+goldsmiths&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyDQgBEC4YrwEYxwEYgAQyBggCEEUYQDIHCAMQABiABDIHCAQQABiABDIGCAUQRRg80gEIMTY5M2owajeoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x54900bfb67ea4211:0x9ff9f645d5806575,1" target="_blank" class="review_read_more" rel="noreferrer">Read more...</a></p>
                                    <ul class="reviews_icon">
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                    </ul>
                                    <h3 class="name">Hailee C</h3>
                                </div>
                            </Slider>
                        </Tab>
                        <Tab eventKey="profile" title={<img src={yelp} alt="yelp-reviews" width={130} height={76} />}>
                            <Slider {...settings2}>
                                <div class="item-testimo2 text-center">
                                    <p class="desc ">Great shop. Awesome staff.
                                        Answered all my questions and more .
                                        They are fixed a chain on my bracelet . <a rel="noreferrer" href="https://www.yelp.com/biz/duvall-goldsmiths-duvall#reviews" target="_blank" class="review_read_more">Read more...</a></p>
                                    <ul class="reviews_icon">
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                    </ul>
                                    <h3 class="name">TPatty B.</h3>
                                </div>

                                <div class="item-testimo2 text-center">
                                    <p class="desc ">I highly recommend them. Very professional. I had 3 rings which needed to be resized and they were done flawlessly within a day. <a rel="noreferrer" href="https://www.yelp.com/biz/duvall-goldsmiths-duvall#reviews" target="_blank" class="review_read_more">Read more...</a></p>
                                    <ul class="reviews_icon">
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                    </ul>
                                    <h3 class="name">Bettina S.</h3>
                                </div>

                                <div class="item-testimo2 text-center">
                                    <p class="desc ">This place is amazing--my husband found it for repair of antique pocket watches a few years ago, since then we have had rings sized, jewelry pieces repaired--and insurance appraisals done.  <a rel="noreferrer" href="https://www.yelp.com/biz/duvall-goldsmiths-duvall#reviews" target="_blank" class="review_read_more">Read more...</a></p>
                                    <ul class="reviews_icon">
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                        <li> <img src={star} alt="Star Rating" /> </li>
                                    </ul>
                                    <h3 class="name">Peggy R.</h3>
                                </div>
                            </Slider>
                        </Tab>
                        {/* <Tab eventKey="contact" title={<img src={ivouch} alt="ivouch-reviews" width={130} height={76} />}>
                            <Slider {...settings2}>
                                <div class="testimo-slider2">
                                    <div class="wrap-item owl-carousel owl-loaded owl-drag" data-navigation="false" data-transition="fade" data-autoplay="true" data-itemscustom="[[0,1]]">
                                        <div class="item-testimo2 text-center">
                                            <p class="desc ">Very satisfied with my experience with Precious Diamonds, i bought my girlfriend 2 earrings for Bday  and she loved it! High quality products and the service was exceptional <a href="https://www.google.com/search?q=duvall+goldsmiths&rlz=1C1CHZN_enIN1092IN1092&oq=duvall+goldsmiths&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyDQgBEC4YrwEYxwEYgAQyBggCEEUYQDIHCAMQABiABDIHCAQQABiABDIGCAUQRRg80gEIMTY5M2owajeoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x54900bfb67ea4211:0x9ff9f645d5806575,1" target="_blank" class="review_read_more">Read more...</a></p>
                                            <ul class="reviews_icon">
                                                <li> <img src={star} alt="Star Rating" /> </li>
                                                <li> <img src={star} alt="Star Rating" /> </li>
                                                <li> <img src={star} alt="Star Rating" /> </li>
                                                <li> <img src={star} alt="Star Rating" /> </li>
                                                <li> <img src={star} alt="Star Rating" /> </li>
                                            </ul>
                                            <h3 class="name">Harris Hodges.</h3>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </Tab> */}
                    </Tabs>

                </div>

            </Container>
        </div>
    )
}

export default GoogleReview