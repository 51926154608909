import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import diamondimg from '../Assets/img/h-diamond.fe2b197459a1385432fe.png'
import showroomimg from '../../../../Assets/images/showroom.png'
import h_pattern from '../Assets/img/h-pattern-1.svg'
const LearneDiamondShowroom = () => {
    return (
        <Container className="LearneDiamondShowroom London_theme_conteiner">
            <Row>
                <Col md={6} className="am1_box_mobile">
                    <div className="zoom-image">
                        <img src={diamondimg} className="img-responsive" alt="Diamonds" />
                            <div className="position-btn">
                                <a href="/education">Learn More</a>
                            </div>
                    </div>
                    <div className="contentsec">
                        <img src={h_pattern} alt="Pattern"/>
                            <h1>Learn Diamond</h1>
                            <p>Until the middle of the twentieth century, there was no agreed-upon standard by which diamonds could be judged.</p>
                    </div>
                </Col>
                <Col md={6} className="am1_box_mobile">
                    <div className="zoom-image">
                        <img src={showroomimg} className="img-responsive" alt="Our Showroom"/>
                            <div className="position-btn">
                                <a href="/our-story">Learn More</a>
                            </div>
                    </div>
                    <div className="contentsec">
                        <img src={h_pattern} alt="Pattern"/>
                            <h1>Our Showroom</h1>
                            <p>Visit our showroom, and talk to a friendly diamond expert to find out how we can best cater to your budget and design requirements.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default LearneDiamondShowroom