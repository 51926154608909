import React from 'react'
// import Container from '../ui/Container'
import { Col, Container, Image, Row } from 'react-bootstrap'
import CustomImg from "../Assets/img/custom-design.png"
import Heading from '../ui/Heading'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import repair from '../../../../Assets/images/img1.png'
const CustomDesign = () => {
  return (
    <section className='custom-design section-space pt-0'>
        <Container fluid className='London_theme_conteiner'>
            <Row className='align-items-center'>
                <Col md={6} xs={12} className='text-center'>
                    <Image className='img-fluid' src={repair} alt='' />
                </Col>

                <Col md={6} xs={12}>
                    <Heading>
                        {/* <span className='text-uppercase'>Be the Designer</span>
                        <h2 className='mb-2 title-box2'>Custom Design Jewelry</h2>
                        <p className='mb-4'>Indulge in the luxury of self-expression with our custom design jewelry service. From intricate details to personalized touches, each piece is crafted to reflect your unique style and story. Elevate your look with bespoke treasures that are as exceptional as you are.</p>
                        <Link to='/custom-design' className="custom-design-btn">Get Started</Link> */}
                        {/* <span className='text-uppercase'>Be the Designer</span> */}
                        <h2 className='mb-2 title-box2'>Jewelry Repair</h2>
                        <p className='mb-4'>At Duvall Goldsmiths, we understand that your jewelry is more than just an accessory; it's a cherished treasure. Our expert jewelers specialize in repairing broken jewelry with the utmost care and precision, restoring each piece to its original elegance. Whether it's a delicate heirloom or a modern favorite, we handle every repair with the highest level of craftsmanship, ensuring that your jewelry retains its beauty and value. Trust Duvall Goldsmiths to bring new life to your beloved pieces with our exceptional repair services.</p>
                        <Link to='/jewelry-repair' className="custom-design-btn">Read More</Link>
                    </Heading>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default CustomDesign