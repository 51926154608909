import React, { useState } from 'react';
import { Button, Col, Container, FormControl, Image, InputGroup, Row, Form, Modal } from 'react-bootstrap';
import { base_url, client_name, currency, currencycode, helmet_url, postHeader, Email_details, contact_number } from '../../../../Helpers/request';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Loader from '../../../../Components/Loader';
import Heading from '../ui/Heading';
import { Link } from 'react-router-dom/cjs/react-router-dom';

function SignupTo() {
    const history = useHistory();
    const [newsletteremail, setNewsletteremail] = useState("")
    const [loader, setLoader] = useState(false)
    const newsletter = (e) => {
        e.preventDefault();
        setLoader(true)
        const data = {
            currency_code: currencycode,
            email: newsletteremail
        }
        axios.post(base_url + '/common/subscribe_newsletter', data, {
            headers: postHeader
        })
            .then(res => {
                if (res.data.status == 1) {
                    setLoader(false)
                    setNewsletteremail('');
                    return swal(res.data.message, "", "success");
                } else {
                    setLoader(false)
                    return swal(res.data.message, "", "error");
                }
            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
            });
    }
    return (
        <section className="rcs_signup_to_section">
            <Container>
                <Row className='align-items-center'>
                    <Col md={6}>
                        <div class="subscribe-box">
                            <Heading>
                                <h2 className='text-dark'>Join the list</h2>
                                <p>Become a preferred subscriber and get a special offer on your purchase.</p>
                            </Heading>
                            <div className="rcs_signup_to_input_field">
                                <Form className="gs_news_form w-100" onSubmit={newsletter}>
                                    <InputGroup>
                                        <FormControl
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                            type="email"
                                            value={newsletteremail}
                                            placeholder='Your email address'
                                            required
                                            onChange={(e) => setNewsletteremail(e.target.value)}
                                        />
                                        <Button className="btn shop_btn" type="submit" id="button-addon2">
                                            Subscribe
                                        </Button>
                                    </InputGroup>
                                </Form>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className='border-left'>
                        <div class="subscribe-box">
                        <Heading>
                            <h2 className='text-dark'>Need Help?</h2>
                            <p>Call us <a href={contact_number.link}>{contact_number.text}</a> <br/> <strong>Tuesday-Friday</strong> 10:00 am - 6:00 pm <br/> <strong>Saturday</strong> 10:00 am - 5:00 pm <br/> <strong>Sunday & Monday</strong> Closed <br/> Email us <a href={Email_details.link} class="mail">{Email_details.text}</a></p>
                            </Heading>
                            <Link to="/make-an-appointment" class="btn shop_btn m-auto d-table" data-toggle="modal" data-target="#appModal">Make An Enquiry</Link>
                        </div>
                    </Col>
                </Row>


            </Container>

            <Modal show={loader}>
                <Loader />
            </Modal>
        </section>
    );
}


export default SignupTo;
