import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import RingImg1 from '../Assets/img/rings_1.png'
import RingImg2 from '../Assets/img/rings_2.png'
import RingImg3 from '../Assets/img/rings_3.png'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import aboutimg from "../../../../Assets/images/about.png";
const WhyBuy = () => {
    return (
        <div className="about_main WhyBuy">
            <Container className='London_theme_conteiner'>
                <div className="title-box2 text-center">
                    {/* <h2 className="title">Why Buy From Duvall Goldsmiths</h2> */}
                    <h2 className="title">About Duvall Goldsmiths</h2>
                </div>
                <Row>
                    {/* <Col md={4} xs={12} className="am_box_mobile">
                        <div className="am_box">
                            <img src={RingImg1} alt='' />
                            <h6 className="tt-title">Personalised for you</h6>
                            <p>Experience our friendly service dealing directly with our expert jewelers in a relaxed environment.</p>
                        </div>
                    </Col>
                    <Col md={4} xs={12} className="am_box_mobile">
                        <div className="am_box">
                            <img src={RingImg2} alt='' />
                            <h6 className="tt-title">Personalised for you</h6>
                            <p>Experience our friendly service dealing directly with our expert jewelers in a relaxed environment.</p>
                        </div>
                    </Col>
                    <Col md={4} xs={12} className="am_box_mobile">
                        <div className="am_box">
                            <img src={RingImg3} alt='' />
                            <h6 className="tt-title">Personalised for you</h6>
                            <p>Experience our friendly service dealing directly with our expert jewelers in a relaxed environment.</p>
                        </div>
                    </Col> */}
                    <Col md={6} xs={12} className="am_box_mobile">
                        <img className='img-fluid' src={aboutimg} alt='Duvall Goldsmiths' />
                    </Col>
                    <Col md={6} xs={12} className="am_box_mobile">
                        <div className="am_box">
                            {/* <h6 className="tt-title">Personalised for you</h6> */}
                            <p className='text-left'>Welcome to Duvall Goldsmiths, where craftsmanship meets elegance. With over 60 years of combined experience, our two dedicated In-House master jewelers specialize in creating exquisite custom-made jewelry tailored to your unique vision. Whether you're looking for a one-of-a-kind piece or need expert repairs on your fine jewelry, our skilled artisans are here to ensure every detail is perfect. Trust us to bring your jewelry dreams to life with unparalleled expertise and a commitment to excellence.</p>
                            <div className="lern_more">
                                <div className="text-left">
                                    <Link to="/our-story" className="abt-heading mt-2">Learn More </Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default WhyBuy