import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'
const Video = () => {
    return (
        <div className="Home-banner-video">
            <div className="item-slider item-slider2">
                <div className="banner-thumb video-sec">
                    <div className="video-container">
                        <video id="main-video" width="100%" height="auto" autoplay="autoplay" muted="" loop="" playsinline="">
                            <source src="https://dl2vs6wk2ewna.cloudfront.net/scrap/spruce-video.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="overlay text-center">
                        {/* <h2>Made with passion</h2> */}
                        <h2>Design Your Own Jewelry</h2>
                        <p>Our dedicated artisans include diamond setters, jewelry and polishers who work together to guarantee high standards in craftsmanship.</p>
                        <p>At Duvall Goldsmiths, we specialize in crafting one-of-a-kind pieces that capture the essence of your unique style. Whether you're dreaming of a magnificent engagement ring that tells your love story or a distinctive piece of jewelry that stands out, our master jewelers bring your vision to life with precision and artistry. Each creation is meticulously designed and handcrafted to ensure it is as extraordinary as the moments it celebrates. Discover the magic of bespoke jewelry at Duvall Goldsmiths, where every piece is a masterpiece.</p>
                        <div className="btn-section">
                            <div className="btn btn-outline"><Link to="/custom-design">Learn More</Link></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Video