import React, { useState, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Helmet } from 'react-helmet';
import BannerSlider from '../showcase_2.o/Components/Bannerslider';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from 'react-redux';
import { client_name } from '../../../Helpers/request';
import CustomDesign from './Components/CustomDesign';
import Insta from './Components/insta';
import SignupTo from './Components/SignupTo';
import EngagementWedding from './Components/EngagementWedding';
import WhyBuy from './Components/WhyBuy';
import Video from './Components/Video';
import LearneDiamondShowroom from './Components/LearneDiamondShowroom';
import GoogleReview from './Components/GoogleReview';
import CreateRing from './Components/CreateRing';

const Home = () => {
  const metaDetails = useSelector((state) => state.persistedReducer.meta.meta);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails?.title}</title>
        <meta name="description" content={metaDetails?.description}></meta>
        <meta name="keywords" content={metaDetails?.keywords}></meta>
        {/*Og Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaDetails?.title} />
        <meta property="og:description" content={metaDetails?.description} />
        <meta property="og:url" content={metaDetails?.url} />
        <meta property="og:site_name" content={client_name?.c_name} />
      </Helmet>
      <div className='leanding_page_duvall'>
      <BannerSlider />
      <CreateRing />
      <EngagementWedding />
      <WhyBuy />
      <Video />
      <LearneDiamondShowroom />
      <CustomDesign />
      <GoogleReview />
      {/* <Insta /> */}
      <SignupTo />
      </div>
    </>
  );
};

export default Home;
