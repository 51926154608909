import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Heading from '../ui/Heading'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { isMobileOnly } from 'react-device-detect'

const CreateRing = () => {
    return (
        <div className='CreateRing'>
            <Container className="London_theme_conteiner">
                <Row className="CreateRing-section align-items-center">
                    <Col md={7} className="light-bg">
                        <Heading className='text-left'>
                            <h2 className="title d-none d-md-block mb-3">Create your Own Diamond Ring</h2>
                            <p className="decs mb-3">Select a setting and choose a diamond to create your own diamond engagement ring. 
                            {/* <b>Need help?</b> We're always here. */}
                            </p>
                            <Link to="/ringsettings" className="shop_nw_btn">Start with a setting</Link>
                            <Link to="/diamonds" className="shop_nw_btn">Start with a diamond</Link>
                        </Heading>
                    </Col>
                    <Col md={5} className={isMobileOnly ? "order-first" : ''}>
                        <Heading className='text-center d-none d-xs-block'>
                            <h2>Create your Own Diamond Ring</h2>
                        </Heading>
                        <div className="text-center"><img src="https://newyork.demobw.com/assets/images/home/setting.png" alt="Diamond Engagement setting" /></div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CreateRing