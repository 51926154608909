import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import about1 from '../Assets/img/about-1.jpg'
import about2 from '../Assets/img/about-2.png'
import { Link } from 'react-router-dom'
const EngagementWedding = () => {
    return (
        <>
            <div className="EngagementWedding">
                <Container className='London_theme_conteiner'>
                    <Row>
                        <Col md={6}>
                            <img src={about1} alt="" />
                        </Col>
                        <Col md={6}>
                            <div className="as_desc">
                                <p className="pretitle">Engagement rings</p>
                                <h6 className="tt-title play-font title-32">Uniquely crafted just for you</h6>
                                <p>The pinnacle expression of love, Duvall Goldsmiths Custom-made engagement rings featuring the rarest D-F colour, eye clean diamonds GIA certified conflict free. Made to the highest level of detail by our team of experience Jewelers and Diamond setters.</p>
                                <Link to="/ringsettings" className="btn shop_btn">Shop Now</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="EngagementWedding">
                <Container className='London_theme_conteiner'>
                    <Row>
                        <Col md={6} className="d-none d-xs-block">
                            <img src={about2} alt="" />
                        </Col>
                        <Col md={6} className="">
                            <div className="as_desc pl-0">
                                <p className="pretitle">Wedding rings</p>
                                <h6 className="tt-title play-font title-32">Together forever</h6>
                                <p>View our gorgeous collection of wedding rings, purposefully designed to enhance the overall beauty of your engagement ring and symbolise the love you share for one another.</p>
                                <Link to="/jewelry/wedding-rings" className="btn shop_btn">Shop Now</Link>
                            </div>
                        </Col>
                        <Col md={6} className=" d-none d-lg-block">
                            <img src={about2} alt="" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default EngagementWedding