import React, { useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "../../Assets/css/home.css";
import { isMobile, isMobileOnly } from 'react-device-detect';
import { Skeleton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { base_url, postHeader } from '../../../../Helpers/request';
import { useState } from 'react';
import { toast } from 'react-toastify';
import LazyLoad from 'react-lazy-load';

import bannerImg from '../../../Front/showcase_2.o/Assets/img/homebanner.jpg'

const BannerSlider = () => {
  const history = useHistory();
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    //-----------------------------------Banner
    setLoading(true)
    axios.get(base_url + `/home/slider`, {
      headers: postHeader
    })
      .then(response => {
        if (response.data.status == 1) {
          setLoading(false)
          setBanner(response.data.data);
          localStorage.setItem("bw-bannerdata", JSON.stringify(response.data.data));
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])
  return (
    <>

      {/* {banner?.map((val, index) =>
       <>
        {!loading?  <div className="rcs_hero_slider" style={{ backgroundImage: `url(${isMobileOnly ? val?.mobile_image : val?.image})` }}>
          <div className="rcs_hero_img">
            <Container fluid>
              <Row className='m-auto w-100'>
                <Col md={6} className='p-0'>
                  <div className={`${isMobile ? 'text-center' : val.text_direction_class} text-center`}>
                    <h1 data-aos="fade-up-left" data-aos-duration="700">{(val.title)}</h1>
                    <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300">{(val.short_text)}</p>
                    <Button data-aos="fade-up-left" data-aos-duration="1800" data-aos-delay="400" onClick={() => { history.push('/' + val.btn_link) }} className="outline-base-btn large">{val.btn_text}</Button>
                  </div>                  
                </Col>
              </Row>
            </Container>
          </div>
        </div>:<Skeleton variant="text" animation="wave"/>}
        </>
 )} */}

      <div className="rcs_hero_slider" style={{ backgroundImage: `url(${isMobileOnly ? bannerImg : bannerImg})` }}>
        <div className="rcs_hero_img">
          <Container fluid>
            <Row className={`${isMobile ? 'justify-content-center' : 'justify-content-end'}`}>
              <Col md={6}>
                <div className={`${isMobile ? 'text-center home_banner_content' : 'text-right home_banner_content'}`}>
                  <h1 data-aos="fade-up-left" data-aos-duration="700"><span>CREATE YOUR OWN</span> Diamond Ring </h1>
                  {/* <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300">Select a setting and choose a diamond to create your own diamond engagement ring. <b>Need help?</b> We're always here.</p> */}
                  <Button onClick={() => { history.push('/ringsettings') }} className="outline-base-btn large home-banner-btn2">Start with a Setting <span>&#8594;</span></Button>
                  <Button onClick={() => { history.push('/diamonds') }} className="outline-base-btn large home-banner-btn1">Start with a Diamond <span>&#8594;</span></Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

    </>
  )
}

export default BannerSlider;